import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import './index.css';
import * as serviceWorker from './serviceWorker';

import imgBaseItem from './resources/img/item.png';
import imgOrbTrans from './resources/img/orb/trans.png';
import imgOrbAlt from './resources/img/orb/alt.png';
import imgOrbAug from './resources/img/orb/aug.png';
import imgOrbRegal from './resources/img/orb/regal.png';
import imgOrbChaos from './resources/img/orb/chaos.png';
import imgOrbScour from './resources/img/orb/scour.png';
import imgOrbExalt from './resources/img/orb/exalt.png';
import imgOrbAnnul from './resources/img/orb/annul.png';
import imgOrbAlch from './resources/img/orb/alch.png';
import imgOrbChance from './resources/img/orb/chance.png';
import imgOrbDivine from './resources/img/orb/divine.png';

const rarities = {
    normal: 'normal',
    magic: 'magic',
    rare: 'rare',
    unique: 'unique'
};

const currencyImages = {
    trans: imgOrbTrans,
    alt: imgOrbAlt,
    aug: imgOrbAug,
    regal: imgOrbRegal,
    chaos: imgOrbChaos,
    scour: imgOrbScour,
    exalt: imgOrbExalt,
    annul: imgOrbAnnul,
    alch: imgOrbAlch,
    chance: imgOrbChance,
    divine: imgOrbDivine,
}

const rareAdjectives = [
    'Celebration',
    'Particular',
    'Relaxing',
    'Occasional',
    'Fur-coated',
    'Melting',
    'Outdoors',
    'Triumph',
    'Prosperous',
    'Joyous',
    'Monumental',
    'Aspiring',
    'Putin Admiring'
]

const rareNouns = [
    'One',
    'Puddle',
    'Light',
    'Bundle',
    'Cute',
    'Forest Creature',
    'Occasion',
    'Wonder',
    'Skipping',
    'Gathering',
    'Party',
    'Ritual',
    'Jumpy Time'
]

const prefixMods = [
    {
        name: 'additional_hugs',
        text: 'Area contains # additional hug spots',
        ranks: [
            {
                'name': 'Wrapped',
                'range': _.range(2, 5),
            },
            {
                'name': 'Indulgent',
                'range': _.range(5, 8),
            },
            {
                'name': 'Adorable',
                'range': _.range(8, 11),
            },
            {
                'name': 'Fluffshrouded',
                'range': _.range(11, 16),
            },
        ],
    },
    {
        name: 'additional_smooches',
        text: 'Deers in area receive # additional smooches from everyone',
        ranks: [
            {
                'name': 'Pecked',
                'range': _.range(2, 5),
            },
            {
                'name': 'Smooched',
                'range': _.range(5, 8),
            },
            {
                'name': 'Blushing',
                'range': _.range(8, 11),
            },
            {
                'name': 'Lipstick-covered',
                'range': _.range(11, 16),
            },
        ],
    },
    {
        name: 'cake_chocolate_content',
        text: 'Cake contains #% increased Chocolate Contents',
        ranks: [
            {
                'name': 'Chocolate-touched',
                'range': _.range(50, 70),
            },
            {
                'name': 'Nutella\'d',
                'range': _.range(70, 90),
            },
            {
                'name': 'Tempered',
                'range': _.range(90, 120),
            },
            {
                'name': 'Cocoabursting',
                'range': _.range(120, 160),
            },
        ],
    },
    {
        name: 'stag_appreciation',
        text: 'Local stags appreciate your # and grant you Cookies',
        ranks: [
            {
                'name': 'Appreciated',
                'range': ['Deer Ears', 'Fluffy Fur'],
            },
            {
                'name': 'Honored',
                'range': ['Enormoose Antlers', 'Doe Eyes'],
            },
            {
                'name': 'Decorated',
                'range': ['Cute Bow', 'Tail Consistency'],
            },
            {
                'name': 'Talkative',
                'range': ['Deerly Banter', 'Squeaky Manners'],
            },
            {
                'name': 'Friendly',
                'range': ['Hoof Sounds', 'Belly Rubs'],
            },
            {
                'name': 'Great Host\'s',
                'range': ['Fantastic Cooking', 'Outstanding Hospitality'],
            },
            {
                'name': 'Immortalized',
                'range': ['Input on the next Diablo title'],
            },
        ],
    },
    {
        name: 'cake_flavour',
        text: 'Cake is #-flavoured and you\'ll love it',
        rare: 0.3,
        ranks: [
            {
                'name': 'Fruit-scented',
                'range': ['Orange', 'Strawberry', 'Lemon', 'Peach'],
            },
            {
                'name': 'Creamy',
                'range': ['Hazelnut', 'Mascarpone', 'Pistachio'],
            },
            {
                'name': 'Baked-off',
                'range': ['Galaxy', 'Starlight']
            }
        ],
    },
    {
        name: 'flowers_drop_rate',
        text: '#% increased drop rate of Flowers in area',
        ranks: [
            {
                'name': 'Floral',
                'range': _.range(50, 100),
            },
            {
                'name': 'Spring-Weathered',
                'range': _.range(101, 200),
            },
            {
                'name': 'Cherry Blossom\'s',
                'range': _.range(201, 401),
            }
        ],
    },
    {
        name: 'deer_costumes',
        text: 'Area contains # additional deers in disguise as something cute',
        rare: 0.3,
        ranks: [
            {
                'name': 'Whimsical',
                'range': _.range(2, 5),
            },
            {
                'name': 'Camouflaged',
                'range': _.range(5, 7),
            },
            {
                'name': 'Uncatchable',
                'range': _.range(7, 10),
            },
            {
                'name': 'Downright Confusing',
                'range': _.range(10, 14),
            },
        ],
    },
    {
        name: 'allure',
        text: 'Area is Alluring',
        rare: 0.01,
        ranks: [
            {
                'name': 'Fishy',
            },
        ],
    },
    {
        name: 'poros',
        text: 'Poros roam this area',
        rare: 0.05,
        ranks: [
            {
                'name': 'Snowdown\'s',
            },
        ],
    },
    {
        name: 'love_aoe_overlap',
        text: 'Love in this area has #% increased Area of Effect and can Overlap',
        rare: 0.07,
        ranks: [
            {
                'name': 'Lovely',
                'range': _.range(30, 46),
            },
            {
                'name': 'Amorous',
                'range': _.range(46, 61),
            },
            {
                'name': 'Valentine\'s',
                'range': _.range(61, 81),
            },
        ],
    },
    {
        name: 'wealth',
        text: 'Area contains valuable treasures that will be found and claimed by #',
        rare: 0.03,
        ranks: [
            {
                'name': 'Very Well Off',
                'range': ['You', 'Your Deer'],
            },
            {
                'name': 'Second Placing',
                'range': ['a Mosquito', 'Tikva the Math Eel One'],
            },
        ],
    },
];

const suffixMods = [
    {
        name: 'graze_zone',
        text: 'Area contains plenty of Grass',
        ranks: [
            {
                'name': 'of Grazing',
            },
        ],
    },
    {
        name: 'gift_zone',
        text: 'Gifts cannot be Evaded',
        rare: 0.05,
        ranks: [
            {
                'name': 'of Treasures',
            },
        ],
    },
    {
        name: 'candy_tempest',
        text: 'Area is affected by Candyrain Tempest',
        rare: 0.05,
        ranks: [
            {
                'name': 'of Free Candy',
            },
        ],
    },
    {
        name: 'guest_appearance',
        text: 'Link will attend the party',
        rare: 0.03,
        ranks: [
            {
                'name': 'of Zelda?',
            },
        ],
    },
    {
        name: 'more_particles',
        text: '#% increased effect of Particle Effects on You',
        ranks: [
            {
                'name': 'of Sparkling',
                'range': _.range(50, 100),
            },
            {
                'name': 'of Beholding',
                'range': _.range(100, 200),
            },
            {
                'name': 'of Pyrotechnical Awe',
                'range': _.range(200, 351),
            },
        ],
    },
    {
        name: 'buckbuckbuckbuck',
        text: 'Area contains A BUCK!',
        rare: 0.1,
        ranks: [
            {
                'name': 'of BUUUUCK!',
            },
        ],
    },
    {
        name: 'stupid_beasts',
        text: 'An additional Einhar will attend the party',
        rare: 0.1,
        ranks: [
            {
                'name': 'of Stupid Beasts',
            },
        ],
    },
    {
        name: 'extra_liel',
        text: 'Unique Boss is accompanied by 1 extra Liel',
        rare: 0.005,
        ranks: [
            {
                'name': 'of His Magnitude',
            },
        ],
    },
    {
        name: 'polite_bucks',
        text: 'Area contains # additional very polite Bucks',
        ranks: [
            {
                'name': 'of Manners',
                'range': _.range(2, 4),
            },
            {
                'name': 'of the Tamed',
                'range': _.range(4, 6),
            },
            {
                'name': 'of the Monocle',
                'range': _.range(6, 9),
            },
        ],
    },
    {
        name: 'hoofing_speed',
        text: 'All Deers enjoy #% increased Movement and Tailwag Speed in area',
        ranks: [
            {
                'name': 'of Promptness',
                'range': _.range(21, 26),
            },
            {
                'name': 'of the Business Hamster',
                'range': _.range(26, 31),
            },
            {
                'name': 'of Cake Rumor',
                'range': _.range(31, 41),
            },
        ],
    },
    {
        name: 'antler_fluff',
        text: 'Your Antlers are 50% more # while You are in the Party',
        ranks: [
            {
                'name': 'of Smoothness',
                'range': ['Fluffy', 'Soft'],
            },
            {
                'name': 'of Warmness',
                'range': ['Warming'],
            },
            {
                'name': 'of the Moose',
                'range': ['Branchy', 'Complex'],
            },
        ],
    },
    {
        name: 'toots_chain',
        text: 'Toots that You make chain # additional times',
        ranks: [
            {
                'name': 'of Hype',
                'range': _.range(2, 5),
            },
            {
                'name': 'of Celebration',
                'range': _.range(5, 8),
            },
            {
                'name': 'of Confettisplosion',
                'range': _.range(8, 11),
            },
        ],
    },
    {
        name: '+_level_gems',
        text: '+2 to level of Socketed # Gems',
        rare: 0.1,
        ranks: [
            {
                'name': 'of Brewership',
                'range': ['Coffee'],
            },
            {
                'name': 'of Virtualization',
                'range': ['VR'],
            },
            {
                'name': 'of Great Muse',
                'range': ['Musical'],
            },
        ],
    },
];

const baseItemName = 'Deer Birthday Event';

class Sprite extends React.Component {
    render() {
        return (
            <img src={this.props.art} alt='' onClick={this.props.onClick}></img>
        );
    }
}

class ItemHeader extends React.Component {
    render() {
        let multiline = _.split(this.props.name, '\n');
        let lines = multiline.map((line) => { return (
            <div key={line} className={'item-header-' + this.props.rarity + '-text'}>{line}</div>
        )});

        return (
            <span className={'item-header item-header-' + this.props.rarity}>
                {lines}
            </span>
        );
    }
}

class Mod extends React.Component {
    render() {
        return (
            <div className='item-mod-text'>{this.props.text}</div>
        );
    }
}

class Item extends React.Component {
    render() {
        const mods = this.props.prefixes.concat(this.props.suffixes).map((mod) =>
            <Mod key={mod.name} text={mod.text} name={mod.rank.name}></Mod>
        );

        return (
            <div className='item-box'>
                <ItemHeader rarity={this.props.rarity} name={this.props.name}></ItemHeader>
                <div className='item-mods'>
                    {mods}
                </div>
                <div className='base-item-art-grid'>
                <span></span>
                <span>
                    <Sprite art={imgBaseItem}></Sprite>
                </span>
                <span></span>
                </div>
            </div>
        );
    }
}

class CurrencyGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currencies: [
                'trans',
                'alt',
                'aug',
                'regal',
                'scour',
                'chance',
                'alch',
                'chaos',
                'exalt',
                'divine',
                'annul',
            ]
        };
    }

    render() {
        const currencySprites = this.state.currencies.map((currencyName) =>
            <span className='currency-cell' key={currencyName}>
                <Sprite
                    art={currencyImages[currencyName]}
                    onClick={() => this.props.onCurrencyUsed(currencyName)}>
                </Sprite>
            </span>
        );

        return (
            <div className='currency-grid'>
                {currencySprites}
            </div>
        );
    }
}

class CraftingApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rarity: rarities.normal,
            modCount: 0,
            prefixCount: 0,
            suffixCount: 0,
            prefixes: [],
            suffixes: [],
            itemName: null,
            currencyHandlers: {
                'trans': this.handleTransmute,
                'alt': this.handleAlteration,
                'aug': this.handleAugmentation,
                'regal': this.handleRegal,
                'alch': this.handleAlchemy,
                'chaos': this.handleChaos,
                'exalt': this.handleExalt,
                'divine': this.handleDivine,
                'scour': this.handleScouring,
                'chance': this.handleChance,
                'annul': this.handleAnnulment,
            },
        };
    }

    generateRareItemName() {
        return _.sample(rareAdjectives) + ' ' + _.sample(rareNouns) + '\n' + baseItemName;
    }

    determineItemName() {
        let itemName = baseItemName;

        switch (this.state.rarity) {
            case rarities.magic:
                if (this.state.prefixCount) {
                    itemName = this.state.prefixes[0].rank.name + ' ' + itemName;
                }

                if (this.state.suffixCount) {
                    itemName += ' ' + this.state.suffixes[0].rank.name;
                }

                return itemName;

            case rarities.rare:
                return this.state.itemName;

            case rarities.normal:
                return baseItemName;

            default:
                return baseItemName
        }
    }

    chooseNewMod(modPool, currentMods) {
        const currentModNames = currentMods.map((mod) => mod.name);

        while (true) {
            let chosenMod = _.sample(modPool);
            if (!_.includes(currentModNames, chosenMod.name) && (!chosenMod.rare || _.random(true) < chosenMod.rare)) {
                let chosenRank = _.sample(chosenMod.ranks);
                let chosenValue = _.sample(chosenRank.range);

                return {
                    name: chosenMod.name,
                    rank: {
                        name: chosenRank.name,
                        range: chosenRank.range,
                        value: chosenValue,
                    },
                    text: _.replace(chosenMod.text, '#', _.toString(chosenValue)),
                    originalText: chosenMod.text,
                };
            }
        }
    }

    addRandomMods(minAmount, maxAmount) {
        const maxAffixesPerType = this.state.rarity === rarities.rare ? 3 : 1;

        let openPrefixes = maxAffixesPerType - this.state.prefixCount;
        let openSuffixes = maxAffixesPerType - this.state.suffixCount;

        let prefixes = this.state.prefixes;
        let suffixes = this.state.suffixes;

        let addedPrefixes = 0;
        let addedSuffixes = 0;

        let affixesToAdd = _.random(minAmount, maxAmount);

        while (affixesToAdd > 0) {
            let modTypeWeighing = _.times(openPrefixes, () => 'P').concat(_.times(openSuffixes, () => 'S'));
            let chosenModType = _.sample(modTypeWeighing);

            let prefixChosen = chosenModType === 'P';

            let affixPool = prefixChosen ? prefixMods : suffixMods;
            let modsOnItem = prefixChosen ? prefixes : suffixes;

            openPrefixes -= prefixChosen ? 1 : 0;
            openSuffixes -= prefixChosen ? 0 : 1;

            prefixChosen ? addedPrefixes++ : addedSuffixes++;

            let chosenMod = this.chooseNewMod(affixPool, modsOnItem);

            if (prefixChosen) {
                prefixes = prefixes.concat(chosenMod);
            } else {
                suffixes = suffixes.concat(chosenMod)
            }

            affixesToAdd--;
        }

        this.setState({
            modCount: this.state.modCount + addedPrefixes + addedSuffixes,
            prefixCount: this.state.prefixCount + addedPrefixes,
            suffixCount: this.state.suffixCount + addedSuffixes,
            prefixes: prefixes,
            suffixes: suffixes,
        });
    }

    addRandomMod() {
        this.addRandomMods(1, 1);
    }

    rerollMods(minAmount, maxAmount) {
        this.clearMods(() => this.addRandomMods(minAmount, maxAmount));
    }

    clearMods(callback) {
        this.setState({
            modCount: 0,
            prefixCount: 0,
            suffixCount: 0,
            prefixes: [],
            suffixes: [],
        }, callback);
    }

    clearSingleMod() {
        let modTypeWeighing = _.times(this.state.prefixCount, () => 'P').concat(_.times(this.state.suffixCount, () => 'S'));
        let chosenModType = _.sample(modTypeWeighing);

        let prefixChosen = chosenModType === 'P';

        let remainingPrefixes = this.state.prefixCount - (prefixChosen? 1 : 0);
        let remainingSuffixes = this.state.suffixCount - (prefixChosen? 0 : 1);

        this.setState({
            modCount: remainingPrefixes + remainingSuffixes,
            prefixCount: remainingPrefixes,
            suffixCount: remainingSuffixes,
            prefixes: _.sampleSize(this.state.prefixes, remainingPrefixes),
            suffixes: _.sampleSize(this.state.suffixes, remainingSuffixes),
        });
    }

    rerollExplicitMods() {
        const rerolledPrefixes = this.rerollModValues(this.state.prefixes);
        const rerolledSuffixes = this.rerollModValues(this.state.suffixes);

        this.setState({
            prefixes: rerolledPrefixes,
            suffixes: rerolledSuffixes,
        });
    }

    rerollModValues(existingMods) {
        let newMods = existingMods.map((mod) => {
           let newValue = _.sample(mod.rank.range);

           let newMod = mod;
           newMod.rank.value = newValue;
           newMod.text = _.replace(mod.originalText, '#', newValue);

           return newMod;
        });

        return newMods;
    }

    handleTransmute() {
        if (this.state.rarity !== rarities.normal) {
            return;
        }

        this.setState({
            rarity: rarities.magic
        }, () => {this.addRandomMods(1, 2)});
    }

    handleAlteration() {
        if (this.state.rarity !== rarities.magic) {
            return;
        }

        this.rerollMods(1, 2);
    }

    handleAugmentation() {
        if (this.state.rarity !== rarities.magic || this.state.modCount > 1) {
            return;
        }

        this.addRandomMod();
    }

    handleRegal() {
        if (this.state.rarity !== rarities.magic) {
            return;
        }

        this.setState({
            rarity: rarities.rare,
            itemName: this.generateRareItemName(),
        }, () => this.addRandomMod());
    }

    handleAlchemy() {
        if (this.state.rarity !== rarities.normal) {
            return;
        }

        this.setState({
            rarity: rarities.rare,
            itemName: this.generateRareItemName(),
        }, () => this.addRandomMods(4, 6));
    }

    handleChaos() {
        if (this.state.rarity !== rarities.rare) {
            return;
        }

        this.setState({
            itemName: this.generateRareItemName(),
        }, this.rerollMods(4, 6));
    }

    handleExalt() {
        if (this.state.rarity !== rarities.rare || this.state.modCount > 5) {
            return;
        }

        this.addRandomMod();
    }

    handleDivine() {
        if (this.state.rarity === rarities.normal) {
            return;
        }

        this.rerollExplicitMods();
    }

    handleScouring() {
        if (this.state.rarity === rarities.magic || this.state.rarity === rarities.rare) {
            this.setState({
                'rarity': rarities.normal,
            }, () => this.clearMods());
        }
    }

    handleChance() {
        if (_.random(true) > 0.15) {
            this.handleTransmute();
        } else {
            this.handleAlchemy();
        }
    }

    handleAnnulment() {
        if (this.state.modCount > 0) {
            this.clearSingleMod();
        }
    }

    handleCurrency(currencyName) {
        this.state.currencyHandlers[currencyName].bind(this)();
    }

    render() {
        return (
            <div>
                <Item
                    rarity={this.state.rarity}
                    modCount={this.state.modCount}
                    prefixCount={this.state.prefixCount}
                    suffixCount={this.state.suffixCount}
                    prefixes={this.state.prefixes}
                    suffixes={this.state.suffixes}
                    name={this.determineItemName()}
                >
                </Item>
                <CurrencyGrid
                    onCurrencyUsed={(name) => this.handleCurrency(name)}
                >
                </CurrencyGrid>
            </div>
        );
    }
}

ReactDOM.render(<CraftingApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
